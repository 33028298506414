import { computed } from 'vue';
import { useStore } from 'vuex-composition-helpers';
import { nameSpace as datetimeNameSpace } from '@/vue/stores/DateTime/DateTimeStore';
import { DATE_FORMAT } from '@emobg/web-utils';

export const useDateTime = () => {
  const store = useStore();

  const currentStartDateTime = computed(
    () => store.getters[`${datetimeNameSpace}/getStartDateTime`],
  );
  const currentEndDateTime = computed(
    () => store.getters[`${datetimeNameSpace}/getEndDateTime`],
  );
  const currentDateTime = computed(
    () => store.getters[`${datetimeNameSpace}/getDateTime`],
  );

  const setCurrentStartDateTime = payload => store.dispatch(`${datetimeNameSpace}/setStartDateTime`, payload);
  const setCurrentEndDateTime = payload => store.dispatch(`${datetimeNameSpace}/setEndDateTime`, payload);
  const setCurrentDateTime = payload => store.dispatch(`${datetimeNameSpace}/setDateTime`, payload);

  const dateFormat = date => date.format(DATE_FORMAT.filter);

  return {
    currentStartDateTime,
    currentEndDateTime,
    currentDateTime,
    setCurrentStartDateTime,
    setCurrentEndDateTime,
    setCurrentDateTime,
    dateFormat,
  };
};
