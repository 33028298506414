import { computed } from 'vue';
import { useStore } from 'vuex-composition-helpers';
import { nameSpace as activeTabNameSpace } from '@/vue/stores/ActiveTab/ActiveTabStore';

export const useActiveTab = () => {
  const store = useStore();

  const activeTabValue = computed(
    () => store.getters[`${activeTabNameSpace}/getActiveTabValue`],
  );

  const setActiveTabValue = payload => store.dispatch(`${activeTabNameSpace}/setActiveTabValue`, payload);
  const resetActiveTabStore = () => store.dispatch(`${activeTabNameSpace}/resetActiveTabStore`);

  return {
    activeTabValue,
    setActiveTabValue,
    resetActiveTabStore,
  };
};
