import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import map from 'lodash/map';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import size from 'lodash/size';
import some from 'lodash/some';
import sortBy from 'lodash/sortBy';

import { external } from '@emobg/web-api-client';
import {
  DATE_FORMAT,
  DELAY,
  snakeCaseKeys,
} from '@emobg/web-utils';
import { computed } from 'vue';
import { errorNotification } from '@/handlers/errorHandler.const';
import { LOCATION_TYPES } from '@/constants/location.const';
// Mappers
import { getCurrentCity } from '@/stores/City/CityMapper';
import { getEndDateTime, getStartDateTime } from '@/stores/DateTime/DateTimeMapper';
import { getCurrentBookingType } from '@/stores/Booking/stores/BookingType/BookingTypeMapper';
import { currentProfile } from '@/stores/User/Profile/ProfileMapper.js';
import { estimatedTripMileage } from '@/stores/Booking/stores/TripMileage/TripMileageMapper';
import { validateLocationsForEmployee } from '@/helpers/availability/locationValidationHelper';
import {
  addAfterAvailabilityAction,
  addBeforeAvailabilityAction,
  availabilityOldValues,
  getAfterAvailabilityActions,
  getAvailabilityRange,
  getBeforeAvailabilityActions,
  getEmployeeProfile,
  getEmployeeProfileUuid,
  getLocations,
  getOldBookingTypeValue,
  getOldCityValue,
  getOldEndValue,
  getOldProfileValue,
  getOldStartValue,
  getFiltersAppliedCounter,
  previousAvailables,
  resetAvailabilityStore,
  setAvailabilityResponse,
  setEmployeeProfile,
  setOldBookingTypeValue,
  setOldCityValue,
  setOldEndValue,
  setOldProfileValue,
  setOldStartValue,
  setWaitingGeolocation,
  toggleAvailabilityLoading,
} from '@Vehicle/store/Availability/AvailabilityMapper';
import { useAvailabilityFilter } from './useAvailabilityFilters';

/**
 * Validates a Employee Badge and DriverLicense
 * @param {Object} location Location Object
 * @returns {Boolean} isBadgeOk && isDrivingLicenseOk
 */
const dateFormat = date => date.format(DATE_FORMAT.filter);
const AVAILABILITY_RADIUS = 1000000;
export const useAvailability = () => {
  const filtersToSanitize = ['vehicleCategory', 'doors', 'seats', 'transmission', 'fuelType', 'bookingMode'];
  const {
    currentAvailabilityFilter,
    setOldAvailabilityFilters,
  } = useAvailabilityFilter();
  const currentStartDateTime = computed(getStartDateTime);
  const currentEndDateTime = computed(getEndDateTime);
  const currentCity = computed(getCurrentCity);
  const currentSelectedProfile = computed(currentProfile);
  const currentBookingType = computed(getCurrentBookingType);
  const currentEstimatedTripMileage = computed(estimatedTripMileage);

  // COMPUTED STATE
  const currentAvailabilityOldValues = computed(availabilityOldValues);
  const previousAvailabilityValues = computed(previousAvailables);

  // COMPUTED GETTERS
  const filtersAppliedCounter = computed(getFiltersAppliedCounter);
  const oldCityAvailabilityValue = computed(getOldCityValue);
  const oldProfileAvailabilityValue = computed(getOldProfileValue);
  const oldBookingTypeAvailabilityValue = computed(getOldBookingTypeValue);
  const oldStartAvailabilityValue = computed(getOldStartValue);
  const oldEndAvailabilityValue = computed(getOldEndValue);
  const locationsAvailables = computed(getLocations);
  const beforeAvailabilityActions = computed(getBeforeAvailabilityActions);
  const afterAvailabilityActions = computed(getAfterAvailabilityActions);
  const availabilityRange = computed(getAvailabilityRange);
  const currentEmployeeProfileUuid = computed(getEmployeeProfileUuid);
  const currentEmployeeProfile = computed(getEmployeeProfile);

  // COMPUTED METHODS

  const isBookingOnBehalf = computed(() => (
    currentSelectedProfile.value.isBusinessProfile() && currentSelectedProfile.value.getUUID() !== currentEmployeeProfileUuid.value));
  const getProfileUuidSelected = computed(() => (
    (currentSelectedProfile.value.isBusinessProfile() && currentEmployeeProfileUuid.value)
      ? currentEmployeeProfileUuid.value
      : currentSelectedProfile.value.getUUID()
  ));

  const getSelectedEmployee = computed(() => (
    (currentSelectedProfile.value.isBusinessProfile() && currentEmployeeProfileUuid.value)
      ? currentEmployeeProfile.value
      : currentSelectedProfile.value
  ));
  const getLocationsAvailables = computed(() => (
    locationsAvailables.value
  ));

  // METHODS
  const isCurrentStartDifferent = () => {
    if (isNil(oldStartAvailabilityValue.value)) {
      return true;
    }
    return !oldStartAvailabilityValue.value.isSame(currentStartDateTime.value);
  };

  const isCurrentEndDifferent = () => {
    if (isNil(oldEndAvailabilityValue.value)) {
      return true;
    }
    return !oldEndAvailabilityValue.value.isSame(currentEndDateTime.value);
  };

  const isCurrentCityDifferent = () => {
    if (isNil(oldCityAvailabilityValue.value)) {
      return true;
    }
    return oldCityAvailabilityValue.value.getUUID() !== currentCity.value.getUUID();
  };

  const isCurrentBookingTypeDifferent = () => {
    if (isNil(oldBookingTypeAvailabilityValue.value)) {
      return true;
    }
    return oldBookingTypeAvailabilityValue.value.getCode !== currentBookingType.value.getCode();
  };

  const isCurrentProfileDifferent = () => {
    if (isNil(oldProfileAvailabilityValue.value)) {
      return true;
    }
    return oldProfileAvailabilityValue.value.getUUID() !== currentSelectedProfile.value.getUUID();
  };

  const isCurrentFilterDifferent = () => {
    const allOldFilters = pickBy(currentAvailabilityOldValues.value, filters => (isArray(filters) ? size(filters) : !isEmpty(filters)));
    const oldFilters = snakeCaseKeys(pick(allOldFilters, filtersToSanitize));
    const newFilters = filter(currentAvailabilityFilter.value, filters => size(filters.applied));
    const oldFiltersKeys = sortBy(keys(oldFilters));
    const newFiltersKeys = sortBy(map(newFilters, 'filterName'));
    const areFiltersEqual = (size(oldFilters) && size(newFiltersKeys)) && isEqual(oldFiltersKeys, newFiltersKeys);
    if (areFiltersEqual) {
      const filtersDiff = map(
        newFilters, newFilterData => {
          const newFilter = find(newFilters, ['filterName', newFilterData.filterName]);
          const newFiltersValues = sortBy(get(newFilter, 'applied'));
          const oldFilterValues = sortBy(get(oldFilters, newFilterData.filterName));
          return !isEqual(newFiltersValues, oldFilterValues);
        },
      );
      return some(filtersDiff);
    }
    return true;
  };

  const assignCurrentValuesToOldValue = () => {
    setOldStartValue(currentStartDateTime.value);
    setOldEndValue(currentEndDateTime.value);
    setOldProfileValue(currentSelectedProfile.value);
    setOldBookingTypeValue(currentBookingType.value);
    setOldCityValue(currentCity.value);
    setOldAvailabilityFilters();
  };

  const areOldValuesDifferent = force => {
    if (force) {
      return force;
    }

    // When Force is false, we check if there is any value different
    // between current value and old value.
    const startDifferent = isCurrentStartDifferent();
    const endDifferent = isCurrentEndDifferent();
    const cityDifferent = isCurrentCityDifferent();
    const bookingTypeDiff = isCurrentBookingTypeDifferent();
    const profileDiff = isCurrentProfileDifferent();
    const isDateDifferent = startDifferent || endDifferent;
    const filtersAreDifferent = isCurrentFilterDifferent();
    return isDateDifferent || cityDifferent || bookingTypeDiff
      || profileDiff || filtersAreDifferent;
  };

  const isUnknownCity = (city = {}) => !city.uuid;

  // eslint-disable-next-line consistent-return
  const getUserAvailability = async force => {
    const mustCallAvailability = !!force;
    if (!areOldValuesDifferent(mustCallAvailability)) {
      return new Promise(resolve => {
        const valueSetter = previousAvailabilityValues.value;
        setAvailabilityResponse(valueSetter);
        toggleAvailabilityLoading(false);
        resolve(valueSetter);
      });
    }

    toggleAvailabilityLoading(true);
    // Call actions to do before endpoint
    beforeAvailabilityActions.value.callback();
    // Refresh old values
    assignCurrentValuesToOldValue();
    if (isUnknownCity(currentCity.value)) {
      return new Promise(resolve => {
        // Simulate a fast Api call
        // without results
        setTimeout(() => {
          const data = {
            locations: [],
            start_availability: dateFormat(currentStartDateTime.value),
            end_availability: dateFormat(currentEndDateTime.value),
          };
          setAvailabilityResponse(data);
          // Call actions to do after endpoint
          // afterAvailabilityActions.value.callback()
          toggleAvailabilityLoading(false);
          resolve(data);
        }, DELAY.long);
      });
    }
    // TODO: Debate - Shall city / booking_type and profile being taken by the filters?
    const commonRequestParams = {
      profile_uuid: getProfileUuidSelected.value,
      city_uuid: currentCity.value.getUUID(),
      booking_type: currentBookingType.value.getCode(),
      start: dateFormat(currentStartDateTime.value),
      end: dateFormat(currentEndDateTime.value),
    };
    const pickFilters = pick(currentAvailabilityOldValues.value, filtersToSanitize);
    const pickByFilters = pickBy(pickFilters, size);
    const sanitizedFilters = snakeCaseKeys(pickByFilters);

    const availabilityRequest = external.availability.getAvailability({
      ...commonRequestParams,
      ...sanitizedFilters,
      gps_lat: currentCity.value.getLatitude(),
      gps_lng: currentCity.value.getLongitude(),
      trip_mileage: currentEstimatedTripMileage.value,
      radius: AVAILABILITY_RADIUS,
    }, 'v4');

    const locationsRequest = external.availability.getAvailabilityLocations(
      commonRequestParams.city_uuid,
      commonRequestParams,
    );
    try {
      const [availabilityResponse, locationsResponse] = await Promise.all([availabilityRequest, locationsRequest]);
      const locationsMerged = availabilityResponse.locations.map(location => {
        const locationFound = find(locationsResponse, { uuid: location.uuid });
        return {
          ...locationFound,
          ...location,
          is_geofence: locationFound.type === LOCATION_TYPES.geofence,
          vehicles_available: locationFound.show_vehicle_availability
            ? location.vehicles_available
            : null,
        };
      });
      const locations = isBookingOnBehalf.value
        ? await validateLocationsForEmployee(locationsMerged)
        : locationsMerged;

      const data = {
        ...availabilityResponse,
        locations,
      };

      setAvailabilityResponse(data);
      afterAvailabilityActions.value.callback();
      toggleAvailabilityLoading(false);
    } catch (error) {
      setAvailabilityResponse({ locations: [] });
      toggleAvailabilityLoading(false);
      afterAvailabilityActions.value.callback();
      //
      throw new Error(errorNotification(error));
    }
  };
  return {
    oldStartAvailabilityValue,
    oldEndAvailabilityValue,
    locationsAvailables,
    availabilityRange,
    currentBookingType,
    currentEmployeeProfile,
    isBookingOnBehalf,
    getProfileUuidSelected,
    getSelectedEmployee,
    getLocationsAvailables,
    addBeforeAvailabilityAction,
    addAfterAvailabilityAction,
    setOldCityValue,
    setAvailabilityResponse,
    resetAvailabilityStore,
    setWaitingGeolocation,
    setEmployeeProfile,
    getUserAvailability,
    filtersAppliedCounter,
  };
};
