import { computed } from 'vue';
import { useStore } from 'vuex-composition-helpers';
import invoke from 'lodash/invoke';
import { external } from '@emobg/web-api-client';
import { nameSpace } from '@/vue/stores/Locations/LocationsStore';
import { errorNotification } from '@/handlers/errorHandler.const';

export const useLocations = () => {
  const store = useStore();

  const getLocationsCollection = computed(
    () => store.getters[`${nameSpace}/getLocationsCollection`],
  );

  const setLocationCollection = payload => store.dispatch(`${nameSpace}/setLocationCollection`, payload);

  const fetchLocationsByCity = async cityUUID => {
    // Try to get locations group by city from sessionStorage
    const locations = invoke(
      window,
      'sessionStorage.getItem',
      `${nameSpace}/locations`,
    );
    const locationsByCity = locations ? JSON.parse(locations) : {};

    // If there are no locations for the city, get them from API
    if (!locationsByCity[`${cityUUID}`]) {
      try {
        const data = await external.fleetCities.getLocations(cityUUID);

        const locationsData = {
          ...locationsByCity,
          [`${cityUUID}`]: data || [],
        };

        window.sessionStorage.setItem(
          `${nameSpace}/locations`,
          JSON.stringify(locationsData),
        );
      } catch (error) {
        throw new Error(errorNotification(error));
      }
    }

    return locationsByCity[`${cityUUID}`];
  };

  return {
    getLocationsCollection,
    setLocationCollection,
    fetchLocationsByCity,
  };
};
